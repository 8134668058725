var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"message"},[(_vm.loading)?_c('div',{staticClass:"loading-wrapper"},[_c('updox-loading',{attrs:{"color":"#003462","size":"80px"}})],1):(_vm.error)?_c('div',[_c('updox-error',{attrs:{"error-details":_vm.errorDetails}})],1):(
      _vm.message.status === 'MESSAGE_EXPIRED' ||
        _vm.message.status === 'PRACTICE_NO_REMINDERS' ||
        _vm.message.status === 'APPT_IS_LOCKED' ||
        _vm.message.status === 'APPT_IS_CANCELLED' ||
        _vm.message.status === 'APPT_IS_RESTRUCTURED'
    )?_c('div',[_c('form-message-unavailable',{attrs:{"expired":_vm.message.status === 'MESSAGE_EXPIRED',"locked":_vm.message.status === 'APPT_IS_LOCKED',"cancelled":_vm.message.status === 'APPT_IS_CANCELLED',"restructured":_vm.message.status === 'APPT_IS_RESTRUCTURED',"appointment-date":_vm.message.appointmentDate,"org-type":_vm.message.orgType,"category":"reminder","language":_vm.message.language}})],1):(
      _vm.message.status == 'UNIQUE_ID_NOT_FOUND' ||
        _vm.message.status == 'NO_LONGER_ACTIVE'
    )?_c('div',[_c('form-message-not-found',{attrs:{"status":_vm.message.status,"language":_vm.message.language}})],1):_c('div',{staticClass:"padding-20"},[_c('div',{staticClass:"message-container",attrs:{"id":("message-" + (_vm.message.id))}},[_c('br'),_c('div',{staticClass:"practice-info"},[_c('div',{staticClass:"name"},[_c('span',[_c('b',[_vm._v(_vm._s(_vm.message.practiceNameUnlessHipaaSafePracticeNameProvided))])])]),(_vm.message.practicePhoneNumber)?_c('div',{staticClass:"phoneNumber"},[_c('span',[_c('b',[_vm._v("Phone:")]),_vm._v(" "+_vm._s(_vm.message.practicePhoneNumber))])]):_vm._e(),(_vm.message.expirationDate)?_c('div',{staticClass:"expirationDate"},[_c('span',[_c('b',[_vm._v("Expires:")]),_vm._v(" "+_vm._s(_vm.message.expirationDate))])]):_vm._e()]),_c('div',{staticClass:"message-body"},[(_vm.message.type === 'INLINE' && _vm.message.html)?_c('p',{staticClass:"message-inline",domProps:{"innerHTML":_vm._s(_vm.message.html)}}):_vm._e(),(_vm.message.type === 'BLOCK' && _vm.message.html)?_c('div',{staticClass:"message-block",domProps:{"innerHTML":_vm._s(_vm.message.html)}}):_vm._e()])])])])}
var staticRenderFns = []

export { render, staticRenderFns }