import { formsHttpClient } from "../api";

const getAppointmentById = async (id, accessCode) => {
  let result = "";

  await formsHttpClient.get(`/appt/${id}/${accessCode}`).then(response => {
    result = response.data;
  });

  return result;
};

export default {
  getAppointmentById
};
